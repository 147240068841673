import websitePage, { headerTypes, footerTypes } from 'hoc/websitePage/websitePage';
import withApplicationFormDrawer from 'hoc/withApplicationFormDrawer/withApplicationFormDrawer';
import NuPage from 'screens/Request/Nu/Nu';

export default withApplicationFormDrawer(
  websitePage({
    routeKey: 'MULTI_PRODUCT_REQUEST',
    footer: footerTypes.NONE,
    header: headerTypes.LANDING_PAGE,
  })(NuPage),
);
