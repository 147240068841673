import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { NuDsContext } from '@nubank/nuds-web/components/NuDSProvider/NuDSProvider';

import FooterBrazil from 'components/ui/Footer/Footer';

import SectionForm from './patterns/SectionForm/SectionForm';
import SectionAllYouNeed from './patterns/SectionAllYouNeed/SectionAllYouNeed';
import SectionComplexity from './patterns/SectionComplexity/SectionComplexity';
import SectionProducts from './patterns/SectionProducts/SectionProducts';
import SectionPress from './patterns/SectionPress/SectionPress';
import SectionPossibilities from './patterns/SectionPossibilities/SectionPossibilities';
import SectionNoBureaucracy from './patterns/SectionNoBureaucracy/SectionNoBureaucracy';
import SectionNoTaxes from './patterns/SectionNoTaxes/SectionNoTaxes';

export function RequestMultiProduct({ onFormVisibilityChange }) {
  const { formatMessage } = useContext(NuDsContext);
  const cardsArea = formatMessage({ id: 'cms.requestNuPage.cardsArea' });

  const cardComponentsMap = {
    SectionProducts,
    SectionAllYouNeed,
    SectionComplexity,
    SectionNoTaxes,
    SectionNoBureaucracy,
    SectionPress,
    SectionPossibilities,
  };

  return (
    <>
      <SectionForm
        onFormVisibilityChange={onFormVisibilityChange}
      />
      {cardsArea.map((card, index) => {
        const CardComponent = cardComponentsMap[card.componentName];
        const key = card.componentName + index;

        if (!CardComponent) return null;
        return <CardComponent key={key} index={index} />;
      })}
      <FooterBrazil languageSwitcher={false} navigation={false} />
    </>
  );
}

RequestMultiProduct.propTypes = {
  onFormVisibilityChange: PropTypes.func.isRequired,
};

export default RequestMultiProduct;
