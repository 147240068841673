import React, { useEffect, useContext } from 'react';
import { NuDsContext } from '@nubank/nuds-web/components/NuDSProvider/NuDSProvider';
import PropTypes from 'prop-types';
import Box from '@nubank/nuds-web/components/Box/Box';
import Grid from '@nubank/nuds-web/components/Grid/Grid';
import Typography from '@nubank/nuds-web/components/Typography/Typography';

import useOpenFormVisibility from 'utils/hooks/useOpenFormVisibility/useOpenFormVisibility';
import generateDPRsForCMSImage from '@nubank/www-latam-commons/utils/cms/images/generateDPRsForCMSImage';

import { StyledShortForm } from './styles/StyledShortForm';

function SectionForm({
  onFormVisibilityChange,
}) {
  const [ref, isFormHidden] = useOpenFormVisibility();
  useEffect(() => {
    onFormVisibilityChange(isFormHidden);
  }, [isFormHidden]);

  const { formatMessage } = useContext(NuDsContext);
  const imgSrcSet = generateDPRsForCMSImage(formatMessage({ id: 'cms.requestNuPage.heroImage' }));
  const optimizedImgSrcSet = {
    xs: imgSrcSet.xs,
    md: imgSrcSet.md,
    lg: {
      '1x': `${imgSrcSet.lg['1x']}?w=1920`,
      '2x': `${imgSrcSet.lg['2x']}?w=1920`,
      '3x': `${imgSrcSet.lg['3x']}?w=1920`,
    },
  };

  return (
    <>
      <Box
        className="SectionForm"
        tag="section"
        display="flex"
        minHeight="100vh"
        paddingTop={{ xs: '12x', md: '24x' }}
        paddingBottom={{ xs: '8x', lg: '20x' }}
        ref={ref}
        backgroundSize={{ xs: 'cover' }}
        backgroundRepeat="no-repeat"
        backgroundPosition={{ xs: 'center', md: 'center left' }}
        backgroundImage={optimizedImgSrcSet}
      >
        <Grid
          position="relative"
          style={{ zIndex: 2 }}
          gridTemplateRows={{ xs: 'calc(80vh - 7rem) auto', md: '1fr auto', lg: 'auto' }}
          alignItems={{ lg: 'center' }}
        >
          <Grid.Child
            gridColumn={{ xs: '1 / -1', md: '1 / span 5', lg: '1 / span 6' }}
          >
            <Box maxWidth={{ md: '500px', lg: '550px' }}>
              <Typography
                tag="h1"
                variant="heading2"
                whiteSpace="pre-line"
                intlKey="cms.requestNuPage.heroTitle"
                paddingBottom={{ xs: '4x', md: '6x', lg: '4x' }}
              />
              <Typography
                tag="p"
                variant="heading4"
                whiteSpace="pre-line"
                intlKey="cms.requestNuPage.heroDescription"
                maxWidth={{ md: '500px', lg: '400px' }}
              />
            </Box>
          </Grid.Child>
          <Grid.Child
            gridColumn={{ xs: '1 / -1', md: '1 / span 3', lg: '9 / span 4' }}
          >
            <StyledShortForm
              formTitle="cms.requestNuPage.shortFormTitle"
            />
          </Grid.Child>
        </Grid>
      </Box>
    </>
  );
}

SectionForm.propTypes = {
  onFormVisibilityChange: PropTypes.func.isRequired,
};

export default React.memo(SectionForm);
